import React from "react";
import { Col, Row } from "react-bootstrap";
import FAQ from "../../components/faq";
import Layout from "../../components/layout";
import SEO from "../../components/seo";
import { SIGNUP_URL } from "../../util/constants";

const RaydiantIntegration = () => {
  const pageExtra = (
    <section className="gray-bg overflow-hidden" style={{ padding: 30 }}>
      <Row>
        <Col className="justify-content-center text-center">
          <p class="lead">
            Ready to give the integration a try? Get your account running in
            minutes.
          </p>
          <a className="m-btn m-btn-theme2nd m-btn-radius" href={SIGNUP_URL}>
            Get Started
          </a>
        </Col>
      </Row>
    </section>
  );

  return (
    <Layout extra={pageExtra}>
      <SEO
        title={"Use Raydiant with Taplist.io"}
        description={
          "The Raydiant platform supports Taplist.io digital beverage menus. Learn more here."
        }
      />
      <section className="section" style={{ paddingTop: 30 }}>
        {/* <Row>
          <Col md={3}>
            <Link to="/integrations">&laquo; Integrations</Link>
          </Col>
        </Row> */}
        <Row>
          <Col className="justify-content-center text-center">
            <h1>Raydiant Integration</h1>
            <p className="lead">
              The Raydiant platform supports Taplist.io digital beverage menus.
            </p>
          </Col>
        </Row>
      </section>
      <section>
        <Row>
          <Col md={4}>
            <h2>What it does</h2>
          </Col>
          <Col md={8}>
            <p className="lead">
              If you're using the Raydiant platform and digital signage
              hardware, you can now add a digital beverage menu to it using the
              Taplist.io integration.
            </p>
            <ul>
              <li>
                <b>Works just like any other playlist item.</b> You can add your
                Taplist.io menu to your Raydiant playlist, just like any other
                application in the Raydiant marketplace.
              </li>
              <li>
                <b>Big, beautiful beer menus.</b> Taplist.io has become the
                standard when it comes to big, beautiful beer menus that make
                your brand look great. Now you can show them off on Raydiant
                devices.
              </li>
              <li>
                <b>Web, QR, &amp; embed menu included.</b> All of our other
                great features work when you use this integration, including our
                excellent and{" "}
                <a href="https://help.taplist.io/article/51-customize-embed-menu">
                  customizable embedded menu
                </a>
                , QR code support, and much more!
              </li>
            </ul>
          </Col>
        </Row>
      </section>
      <section>
        <Row>
          <Col md={4}>
            <h2>How it works</h2>
          </Col>
          <Col md={8}>
            <p className="lead">
              Taking advantage of this integration is really simple. Here's how:
            </p>
            <ol>
              <li>
                <b>Get an account.</b> You'll need a Taplist.io account on our
                Pro plan.{" "}
                <a href="https://taplist.io/signup">Get started now</a>, it only
                takes a few minutes.
              </li>
              <li>
                <b>Link your screens.</b> See the{" "}
                <a href="#activate">activation instructions below</a> to link
                the two systems.
              </li>
            </ol>
          </Col>
        </Row>
      </section>
      <section>
        <Row>
          <Col md={4}>
            <h2>FAQ</h2>
          </Col>
          <Col md={8}>
            <FAQ
              faqItems={[
                {
                  question: "Does this integration cost extra?",
                  answer:
                    "No. Taplist.io does not charge any additional fee for this integration.",
                },
                {
                  question:
                    "Do I need a Raydiant account to use this integration?",
                  answer: (
                    <>
                      Yes, you need to be a Raydiant customer to take advantage
                      of this integration.
                    </>
                  ),
                },
                {
                  question:
                    "Does Taplist.io work without a Raydiant account or Raydiant hardware?",
                  answer: (
                    <>
                      Yes. This integration is intended for those customers who
                      use or wish to use the Raydiant platform. Taplist.io does
                      not require a Raydiant account or Raydiant hardware. Visit
                      our <a href="/hardware">Hardware page</a>
                      for more details.
                    </>
                  ),
                },
              ]}
            />
          </Col>
        </Row>
      </section>
      <section id="activate" style={{ marginTop: "1em" }}>
        <Row>
          <Col md={4}>
            <h2>Activation Instructions</h2>
          </Col>
          <Col md={8}>
            <p className="lead">
              Taking advantage of this integration is really simple. Here's how:
            </p>
            <ol>
              <li>
                <b>Get a Taplist.io account.</b> You'll need a Taplist.io
                account on our Pro plan.{" "}
                <a href="https://taplist.io/signup">Get started now</a>, it only
                takes a few minutes.
              </li>
              <li>
                <b>Create a TV in Taplist.io.</b> Visit the{" "}
                <a href="https://app.taplist.io/dash/displays">Displays page</a>{" "}
                in the Taplist.io dashboard and create a new display. Configure
                and customize any options you like.
              </li>
              <li>
                <b>Copy the access code.</b> On the Taplist.io configuration
                page for the new TV, click <b>Pair display</b>, then{" "}
                <b>Raydiant</b>. Finally, copy the <em>access code</em> that is
                shown to you.
              </li>
              <li>
                <b>Connect to Raydiant.</b> In the Raydiant dashboard, open the{" "}
                <a href="https://dash.raydiant.com/library">Library</a>, the
                press <b>Create</b>. Click on <i>Taplist.io</i>, then paste the
                access code on the next screen and press <b>Done</b>.
              </li>
              <li>
                <b>Add to Raydiant Playlist.</b> Finally, you can add the new
                Raydiant item to any Raydiant playlist and screen. Press{" "}
                <b>Publish</b> when finished.
              </li>
            </ol>
          </Col>
        </Row>{" "}
      </section>
    </Layout>
  );
};

export default RaydiantIntegration;
